<template>
  <base-list :items="items" />
</template>

<script>
import {
  genDefaultItem,
  genMenuItemExecute,
  genMenuItemShowHistory
} from '@/utils/list-generators';
import { useTimerFunction } from '@/compositions/timerFunction';
import { linkedObjectService } from '@/modules/linked-objects/api';
import { inject, onMounted, ref } from 'vue-demi';
import { getControlIcon } from './control-icons';
import { usePopup } from '@/compositions/popup';
import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';

import router from '@/router';

export default {
  name: 'LinkedObjectCardControls',

  setup() {
    const { getNextRoute } = useHistoryStack();
    const popup = usePopup();
    const controlActions = (linkedObjectId, rpc, controlArguments) => [
      genMenuItemExecute(() => {
        popup.open({
          component: () => import('@/components/popup/PopupValuesEdit.vue'),
          props: {
            title: rpc,
            confirmText: 'Execute',
            value: controlArguments.map(arg => ({ label: arg, value: '' })),
            onConfirm: async value => {
              const params = value.reduce((acc, cur) => {
                acc[cur.label] = cur.value;
                return acc;
              }, {});

              return linkedObjectService.createControlExecution(
                linkedObjectId,
                rpc,
                params
              );
            }
          }
        });
      }),
      genMenuItemShowHistory(
        async () => {
          await router.push(
            getNextRoute({
              component: componentAliases.hcs,
              props: {
                bench: 30,
                ids: [linkedObjectId],
                monitoringItem: {
                  id: linkedObjectId,
                  name: linkedObject.value.name,
                  controlName: rpc,
                  type: 'controlItem'
                }
              }
            })
          );
        },
        { disabled: false }
      )
    ];
    const linkedObject = inject('linkedObject');
    const items = ref([]);

    const genLinkedObjectControlItem = ({
      title,
      actions,
      done,
      error,
      ack
    }) => {
      const parsedError = error !== undefined ? JSON.parse(error) : undefined;
      return genDefaultItem({
        title,
        actions,
        iconColor: parsedError ? 'red' : '',
        icon: getControlIcon(ack, done, parsedError)
      });
    };

    const updateControls = async () => {
      const controls = await linkedObjectService.fetchControlsByObjectId(
        linkedObject.value.id
      );

      items.value = controls.schema.schemaControls
        .filter(control => control.rpc === control.argument)
        .map(({ rpc, description }) => {
          const rpcArguments = controls.schema.schemaControls
            .filter(
              control => control.rpc === rpc && control.rpc !== control.argument
            )
            .map(i => i.argument);

          const rpcExecutions = controls.controlExecutions.filter(
            control => control.name === rpc
          );

          return genLinkedObjectControlItem({
            title: description || `${rpc} (No description provided)`,
            error: rpcExecutions[0]?.error,
            ack: rpcExecutions[0]?.ack,
            done: rpcExecutions[0]?.done,
            actions: controlActions(linkedObject.value.id, rpc, rpcArguments)
          });
        });
    };

    const { run } = useTimerFunction(updateControls, {
      timeout: 5000,
      immediate: true
    });

    onMounted(async () => {
      run();
    });

    return { items };
  }
};
</script>

<style></style>
